<template>
  <BTab
    ref="tab"
    v-bind="$attrs"
    v-on="$listeners"
    @error.native="onError"
    @loading.native="onLoading"
    @status.native="onStatus"
    @click="onActivated"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <template #title>
      <slot name="title">
        {{ title }}
      </slot>
      <template v-if="!lazy || $refs.tab.localActive">
        <MiniSpinner v-if="loading" />
        <BIconExclamationCircle v-else-if="error" />
        <span v-else-if="status">{{ status }}</span>
      </template>
    </template>
  </BTab>
</template>

<script>
import { BIconExclamationCircle, BTab } from 'bootstrap-vue'
import px from 'vue-types'

import mixpanel from '@/mixpanel'

import MiniSpinner from './MiniSpinner.vue'

export default {
  name: 'AppTab',
  components: {
    BIconExclamationCircle,
    BTab,
    MiniSpinner,
  },
  props: {
    title: px.string,
  },
  data: () => ({
    error: null,
    loading: false,
    status: null,
  }),
  computed: {
    lazy() {
      return this.$refs?.tab?.computedLazy
    },
  },
  methods: {
    onActivated() {
      mixpanel.track_tab_activated(this.title)
    },
    onError(event) {
      this.error = event.detail
    },
    onLoading(event) {
      this.loading = event.detail
    },
    onStatus(event) {
      this.status = event.detail
    },
  },
}
</script>

<style lang="scss" scoped></style>
