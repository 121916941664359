<template>
  <BTabs ref="tabs" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </BTabs>
</template>

<script>
import { BTabs } from 'bootstrap-vue'

export default {
  name: 'AppTabs',
  components: {
    BTabs,
  },
}
</script>

<style lang="scss" scoped></style>
